import { ColorStripConfig, createColorStrip } from "@mathieucaroff/colorstrip"
import { resolveSearchAndHash } from "./lib/urlParameter"

interface MainConfig extends ColorStripConfig {
  seed: number
  hue: number | undefined
}

function getConfig() {
  return resolveSearchAndHash<MainConfig>(location, {
    diversityRatio: () => 0.25,
    radiusFactor: () => 1,
    secondaryRadiusFactor: ({ radiusFactor }) => radiusFactor(),
    speedFactor: () => 1,
    seed: () => Math.floor(Math.random() * 2 ** 32),
    paletteSeed: ({ seed }) => seed(),
    stripCircleSeed: ({ seed }) => seed(),
    stripCount: () => 10,
    theme: () => "pastelle",
    hue: () => undefined,
    baseHue: ({ hue }) => hue(),
  })
}

let canvas = document.querySelector("canvas")!

function resize() {
  canvas.width = window.innerWidth
  canvas.height = window.innerHeight
}
window.addEventListener("resize", resize)
resize()

let config = getConfig()

console.log("config", config)

let strip = createColorStrip(canvas, config)

strip.play()
